import { JSX } from "react";

export const defaultErrorMessage = "unexpected error";
export const networkErrorMessage = "network error";

export function Error({ message }: { message: string }): JSX.Element {
  message = (message || defaultErrorMessage).toUpperCase();

  return <p className="error">{message}</p>;
}
