import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  JSX,
} from "react";

const cursorPosition = (event: any) => {
  if (event?.touches?.[0]?.clientX) return event.touches[0].clientX;
  if (event?.clientX) return event?.clientX;
  if (event?.nativeEvent?.touches?.[0]?.clientX)
    return event.nativeEvent.touches[0].clientX;
  return event?.nativeEvent?.clientX;
};

export function SwipeToDelete({
  children,
  onDelete,
}: {
  children: ReactNode;
  onDelete: (id: string) => Promise<void>;
}): JSX.Element {
  const disabled = false;
  const transitionDuration = 250;
  const deleteWidth = 75;
  const deleteThreshold = 75;
  const showDeleteAction = true;
  const rtl = false;
  const [touching, setTouching] = useState(false);
  const [translate, setTranslate] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const startTouchPosition = useRef(0);
  const initTranslate = useRef(0);
  const container = useRef<HTMLDivElement>(null);
  const containerWidth: number =
    container.current?.getBoundingClientRect().width || 0;
  const deleteWithoutConfirmThreshold: number =
    containerWidth * (deleteThreshold / 100);

  const onStart = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (disabled) return;
      if (touching) return;
      startTouchPosition.current = cursorPosition(event);
      initTranslate.current = translate + 50;
      setTouching(true);
    },
    [disabled, touching, translate],
  );

  const onMove = useCallback(
    function (event: TouchEvent | MouseEvent) {
      if (!touching) return;
      if (
        !rtl &&
        cursorPosition(event) >
          startTouchPosition.current - initTranslate.current
      )
        return setTranslate(0);
      if (
        rtl &&
        cursorPosition(event) <
          startTouchPosition.current - initTranslate.current
      )
        return setTranslate(0);
      setTranslate(
        cursorPosition(event) -
          startTouchPosition.current +
          initTranslate.current,
      );
    },
    [rtl, touching],
  );

  const onMouseMove = useCallback(
    function (event: MouseEvent): any {
      onMove(event);
    },
    [onMove],
  );

  const onTouchMove = useCallback(
    function (event: TouchEvent): any {
      onMove(event);
    },
    [onMove],
  );

  const onDeleteConfirmed = useCallback(() => {
    setDeleting(() => true);
    window.setTimeout(onDelete, transitionDuration);
  }, [onDelete, transitionDuration]);

  const onDeleteClick = useCallback(() => {
    onDeleteConfirmed();
  }, [onDeleteConfirmed]);

  const onMouseUp = useCallback(
    function () {
      startTouchPosition.current = 0;
      const acceptableMove = -deleteWidth * 0.7;
      const showDelete = showDeleteAction
        ? (rtl ? -1 : 1) * translate < acceptableMove
        : false;
      const notShowDelete = showDeleteAction
        ? (rtl ? -1 : 1) * translate >= acceptableMove
        : true;
      if (notShowDelete) {
        setTranslate(() => 0);
      } else if (showDelete) {
        setTranslate(() => (rtl ? 1 : -1) * deleteWidth);
      }
      setTouching(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      containerWidth,
      deleteWidth,
      deleteWithoutConfirmThreshold,
      onDeleteClick,
      rtl,
      translate,
    ],
  );

  useEffect(() => {
    const root = container.current;
    root?.style.setProperty(
      "--slideTranslate",
      translate * (rtl ? -1 : 1) + "px",
    );
    const shiftDelete = -translate >= deleteWithoutConfirmThreshold;
    root?.style.setProperty(
      `--slideButtonMargin${rtl ? "Right" : "Left"}`,
      (shiftDelete
        ? containerWidth + translate
        : containerWidth - deleteWidth) + "px",
    );
  }, [
    translate,
    deleteWidth,
    containerWidth,
    rtl,
    deleteWithoutConfirmThreshold,
  ]);

  useEffect(() => {
    if (touching) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("mouseup", onMouseUp);
      window.addEventListener("touchend", onMouseUp);
    } else {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    };
  }, [onMouseMove, onMouseUp, onTouchMove, touching]);

  return (
    <div className={`slide${deleting ? " deleting" : ""}`} ref={container}>
      <div className={`delete${deleting ? " deleting" : ""}`}>
        <button onClick={onDeleteClick}>Delete</button>
      </div>
      <div
        className={`content${deleting ? " deleting" : ""}${
          !touching ? " transition" : ""
        }`}
        onMouseDown={onStart}
        onTouchStart={onStart}
      >
        {children}
      </div>
    </div>
  );
}
