import { ChangeEvent, useEffect, useState, JSX } from "react";
import MiniSearch, { SearchResult } from "minisearch";
import { TermCard } from "./TermCard";
import { AddTerm } from "./AddTerm";
import { getTerms } from "./services";
import { Term } from "./interfaces";
import { Options, SORT_METHOD } from "./constants";
import { ascending, descending } from "./utils";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { HttpStatus } from "../shared/constants";
import { NavBar } from "../shared/NavBar";

const termIndex = new MiniSearch({
  fields: ["native", "japanese", "romaji"],
  storeFields: ["native", "japanese", "romaji"],
  searchOptions: {
    fuzzy: false,
    prefix: true,
    processTerm: MiniSearch.getDefault("processTerm"),
  },
  processTerm: (term) =>
    (function (term: string | null) {
      if (term == null) {
        return;
      }
      const isJapanese = containsJapaneseCharacters(term);
      if (!isJapanese) {
        term = term.normalize("NFKD").replace(/[^\w]/g, "").toLowerCase();
      }
      const searchMinLength = isJapanese ? 1 : 2;
      const tokens = [];
      for (let i = 0; i <= term.length - searchMinLength; i++) {
        tokens.push(term.slice(i));
      }
      return tokens;
    })(term),
});
const indexedTermIds = new Set();

function populateIndex(terms: Term[]) {
  terms.forEach((term) => {
    if (indexedTermIds.has(term.id)) {
      return;
    }
    termIndex.add({
      id: term.id,
      native: term.native,
      japanese: term.japanese,
      romaji: term.romaji,
    });
    indexedTermIds.add(term.id);
  });
}

function containsJapaneseCharacters(str: string): boolean {
  const japaneseRegex =
    /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uF900-\uFAFF]/;

  return japaneseRegex.test(str);
}

export function Vocabulary(): JSX.Element {
  const [errors, setErrors] = useState({
    http: "",
  });
  const [terms, setTerms] = useState([] as Term[]);
  const [search, setSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([] as Term[]);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [sortMethod, setSortMethod] = useState(
    localStorage.getItem(SORT_METHOD) ?? Options.Created.KEY,
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setStatus(HttpStatus.LOADING);
    try {
      const result = (await getTerms()) || [];
      switch (sortMethod) {
        case Options.Created.KEY:
          setTerms(result.slice().sort(descending("created_at")));
          break;
        case Options.Edited.KEY:
          setTerms(result.slice().sort(descending("edited_at")));
          break;
        case Options.Name.KEY:
          setTerms(result.slice().sort(ascending("native")));
          break;
      }
      setStatus(HttpStatus.SUCCESS);
      populateIndex(result);
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function sortBy(event: any): void {
    switch (event.target.value) {
      case Options.Created.KEY:
        setTerms(terms.slice().sort(descending("created_at")));
        setSortMethod(Options.Created.KEY);
        localStorage.setItem(SORT_METHOD, Options.Created.KEY);
        break;
      case Options.Edited.KEY:
        setTerms(terms.slice().sort(descending("edited_at")));
        setSortMethod(Options.Edited.KEY);
        localStorage.setItem(SORT_METHOD, Options.Edited.KEY);
        break;
      case Options.Name.KEY:
        setTerms(terms.slice().sort(ascending("native")));
        setSortMethod(Options.Name.KEY);
        localStorage.setItem(SORT_METHOD, Options.Name.KEY);
        break;
    }
  }

  function showSearch(): void {
    setSearch(true);
  }

  function hideSearch(): void {
    setSearch(false);
    setQuery("");
    setSearchResults([]);
  }

  function searchTerm(event: ChangeEvent<HTMLInputElement>): void {
    setQuery(event.target.value);
    const results = termIndex.search(event.target.value);
    const matches: string[] = [];
    results.forEach((result: SearchResult) => {
      matches.push(result.id);
    });
    setSearchResults(terms.filter((t) => matches.includes(t.id)));
  }

  function openDialog(): void {
    setDialogOpen(true);
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100vw";
    document.body.style.top = `-${window.scrollY}px`;
  }

  function closeDialog(): void {
    setDialogOpen(false);
    document.body.style.overflow = "unset";
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(document.body.style.top || "0") * -1);
  }

  return (
    <>
      <div className="header">
        <h1>語彙</h1>
      </div>
      {terms.length > 0 && (
        <div className="tools">
          {search ? (
            <>
              <input
                autoFocus
                className="search flat"
                onChange={(event): void => searchTerm(event)}
              />
              <button className="icon flat" onClick={hideSearch}>
                <i className="material-icons">close</i>
              </button>
            </>
          ) : (
            <>
              <select
                className="select"
                name="order"
                id="order"
                onChange={(event): void => sortBy(event)}
                value={sortMethod}
              >
                <option value={Options.Name.KEY}>{Options.Name.TEXT}</option>
                <option value={Options.Created.KEY}>
                  {Options.Created.TEXT}
                </option>
                <option value={Options.Edited.KEY}>
                  {Options.Edited.TEXT}
                </option>
              </select>
              <button className="icon flat" onClick={showSearch}>
                <i className="material-icons">search</i>
              </button>
            </>
          )}
        </div>
      )}
      {status === HttpStatus.LOADING ? (
        <Loading />
      ) : status === HttpStatus.ERROR ? (
        <Error message={errors.http} />
      ) : terms.length === 0 ? (
        <p className="center loading">No terms</p>
      ) : query !== "" && searchResults.length === 0 ? (
        <p className="center loading">No matches</p>
      ) : (
        <div className="term-cards">
          {(searchResults.length > 0 ? searchResults : terms).map((term) => (
            <TermCard key={term.id} {...{ term: term }} />
          ))}
        </div>
      )}
      {!dialogOpen && (
        <div className="bottom-bar">
          <div></div>
          <div className="button-margin">
            <button className="add icon" onClick={openDialog}>
              <i className="material-icons">add</i>
            </button>
          </div>
        </div>
      )}
      {dialogOpen && <AddTerm close={closeDialog} refresh={getData} />}
      <NavBar />
    </>
  );
}
