import { useEffect, JSX } from "react";
import { characters } from "./data";
import { NavBar } from "../shared/NavBar";

export function Syllabary(): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function generateTable(): JSX.Element[] {
    let table: JSX.Element[] = [];
    let hiraganaRow: JSX.Element[] = [];
    let katakanaRow: JSX.Element[] = [];
    let romajiRow: JSX.Element[] = [];
    characters.forEach((character, index) => {
      hiraganaRow.push(
        <td className="emphasis high" key={character.hiragana || index}>
          {character.hiragana}
        </td>,
      );
      katakanaRow.push(
        <td className="emphasis low" key={character.katakana || index + 1}>
          {character.katakana}
        </td>,
      );
      romajiRow.push(
        <td className="low hint" key={character.romaji || index + 2}>
          {character.romaji}
        </td>,
      );
      if ((index + 1) % 5 === 0) {
        table.push(
          hiraganaRow.some((v) => isNaN(Number(v.key))) ? (
            <tr key={index}>{hiraganaRow}</tr>
          ) : (
            <tr className="empty"></tr>
          ),
          <tr key={index + 1}>{katakanaRow}</tr>,
          <tr key={index + 2}>{romajiRow}</tr>,
        );
        hiraganaRow = [];
        katakanaRow = [];
        romajiRow = [];
      }
    });
    return table;
  }

  return (
    <>
      <div className="header">
        <h1>五十音</h1>
      </div>
      <table className="syllabary">
        <tbody>{generateTable()}</tbody>
      </table>
      <NavBar />
    </>
  );
}
