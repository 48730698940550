import { JSX } from "react";
import { useNavigate } from "react-router-dom";

export function NavBar(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="navbar-tabs">
        <div className={"navbar-tab"} onClick={() => navigate("/vocabulary")}>
          <span className="material-icons">library_books</span>
        </div>
        <div className={"navbar-tab"} onClick={() => navigate("/syllabary")}>
          <span className="material-icons">emoji_symbols</span>
        </div>
        <div className={"navbar-tab"} onClick={() => navigate("/quiz")}>
          <span className="material-icons">quiz</span>
        </div>
        <div className={"navbar-tab"} onClick={() => navigate("/stats")}>
          <span className="material-icons">insights</span>
        </div>
        <div className={"navbar-tab"} onClick={() => navigate("/account")}>
          <span className="material-icons">account_box</span>
        </div>
      </div>
    </div>
  );
}
