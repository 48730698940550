import { useContext, useLayoutEffect, useState, JSX } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "./services";
import { UserInfo } from "./interfaces";
import { AuthContext } from "./AuthContext";
import { logOutUser } from "./services";
import { NavBar } from "../shared/NavBar";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { transformDate } from "../shared/utils";
import { HttpStatus } from "../shared/constants";
import { DeleteAccount } from "./DeleteAccount";

export function Account(): JSX.Element {
  const [errors, setErrors] = useState({
    http: "",
  });
  const [data, setData] = useState({} as UserInfo);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setAuthenticatedUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setStatus(HttpStatus.LOADING);
    (async (): Promise<void> => {
      try {
        const result = await getUserInfo();
        setData(result);
        setStatus(HttpStatus.SUCCESS);
      } catch (error) {
        setErrors((errors) => ({ ...errors, http: (error as Error).message }));
        setStatus(HttpStatus.ERROR);
        console.error((error as Error).cause);
      }
    })();
  }, []);

  async function handleLogOut(): Promise<void> {
    setStatus(HttpStatus.WORKING);
    try {
      await logOutUser();
      setAuthenticatedUser(false);
      setStatus(HttpStatus.SUCCESS);
      navigate("/welcome");
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function openDialog(): void {
    setDialogOpen(true);
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100vw";
    document.body.style.top = `-${window.scrollY}px`;
  }

  function closeDialog(): void {
    setDialogOpen(false);
    document.body.style.overflow = "unset";
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(document.body.style.top || "0") * -1);
  }

  return (
    <>
      <div className="header">
        <h1>アカウント</h1>
      </div>
      {status === HttpStatus.LOADING ? (
        <Loading />
      ) : status === HttpStatus.ERROR ? (
        <Error message={errors.http} />
      ) : (
        <>
          <div className="center">
            <p className="multiline">
              {`こんにちわ ${
                data.name
              }!, your account is registered with the email ${
                data.email
              } and was created on ${transformDate(data.created_at)}.`}
            </p>
          </div>
          <div className="button-group center">
            <button onClick={handleLogOut}>Log out</button>
            <button className="danger" onClick={openDialog}>
              Delete account
            </button>
          </div>
        </>
      )}
      {dialogOpen && <DeleteAccount close={closeDialog} />}
      <NavBar />
    </>
  );
}
