import { ChangeEvent, useEffect, useState, JSX } from "react";
import { addTerm } from "./services";
import { NewTerm } from "./interfaces";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { HttpStatus } from "../shared/constants";

export function AddTerm({
  close,
  refresh,
}: {
  close: () => void;
  refresh: () => void;
}): JSX.Element {
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    http: "",
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [term, setTerm] = useState({} as NewTerm);
  const missingFields = !term.native && !term.japanese && !term.romaji;
  const activeErrors = Object.values(errors).includes(true);

  useEffect(() => {
    if (missingFields || activeErrors) {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [missingFields, activeErrors]);

  function updateTerm(
    event: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void {
    setTerm((term) => ({ ...term, [field]: event.target.value }));
  }

  async function handleAddTerm(): Promise<void> {
    setStatus(HttpStatus.WORKING);
    try {
      await addTerm(term);
      setStatus(HttpStatus.SUCCESS);
      refresh();
      close();
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function cancel(): void {
    close();
  }

  return (
    <div className="backdrop">
      <div className="dialog">
        <div className="title">
          <p>Add term</p>
          <button className="icon" onClick={cancel}>
            <i className="material-icons">close</i>
          </button>
        </div>
        <label>Native</label>
        <input
          onChange={(event) => updateTerm(event, "native")}
          required
          type="text"
          value={term.native}
        />
        <label>Japanese</label>
        <input
          onChange={(event) => updateTerm(event, "japanese")}
          required
          type="text"
          value={term.japanese}
        />
        <label>Romaji</label>
        <input
          onChange={(event) => updateTerm(event, "romaji")}
          required
          type="text"
          value={term.romaji?.toLowerCase()}
        />
        {status === HttpStatus.ERROR && (
          <div className="center">
            <Error message={errors.http} />
          </div>
        )}
        <div className="button-group">
          {status === HttpStatus.WORKING ? (
            <Loading />
          ) : (
            <button disabled={buttonDisabled} onClick={handleAddTerm}>
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
