import { JSX } from "react";
import { useNavigate } from "react-router-dom";

export function Welcome(): JSX.Element {
  const navigate = useNavigate();

  function goToLogIn(): void {
    navigate("/log-in");
  }

  function goToCreateAccount(): void {
    navigate("/create-account");
  }

  return (
    <>
      <h1 className="header">ようこそ</h1>
      <div className="center" style={{ marginTop: "50px" }}>
        <p className="multiline">
          NotoChan is an app that helps you learn Japanese.
          <br /> Learn Hiragana and Katakana, store vocabulary, practice with
          quizzes, and see your level of knowledge.
        </p>
      </div>
      <p
        className="hint center"
        style={{ marginTop: "30px", marginBottom: "50px" }}
      >
        Please note that this is a beta version and may contain errors.
        <br /> If you encounter any unexpected behavior or have some feedback to
        share, please contact me.
        <br /> ありがとうございます！
      </p>
      <div className="button-group">
        <button onClick={goToCreateAccount}>Create account</button>
        <button onClick={goToLogIn}>Log in</button>
      </div>
    </>
  );
}
