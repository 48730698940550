import { useLayoutEffect, useState, JSX } from "react";
import { Details } from "./Details";
import { getQuizStats } from "./services";
import { StatsData } from "./interfaces";
import { titleCase } from "./utils";
import { NavBar } from "../shared/NavBar";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { HttpStatus } from "../shared/constants";
import { formatDecimal } from "../shared/utils";

export function Stats(): JSX.Element {
  const [errors, setErrors] = useState({
    http: "",
  });
  const [stats, setStats] = useState({} as StatsData);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [group, setGroup] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  async function getData() {
    setStatus(HttpStatus.LOADING);
    try {
      const result = await getQuizStats();
      setStats(result);
      setStatus(HttpStatus.SUCCESS);
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function closeDetails(): void {
    setGroup("");
  }

  return (
    <>
      <div className="header">
        <h1>統計</h1>
      </div>
      {status === HttpStatus.LOADING ? (
        <Loading />
      ) : status === HttpStatus.ERROR ? (
        <Error message={errors.http} />
      ) : stats.completed_quizzes === 0 ? (
        <p className="center loading">No stats</p>
      ) : (
        <>
          {group ? (
            <Details stats={stats} group={group} close={closeDetails} />
          ) : (
            <div className="center">
              <p className="stats-label">Global score</p>
              <p className="emphasis header score">
                {formatDecimal(stats.global_score)}
              </p>
              <div className="stats-group">
                {stats.individual_stats?.map((group) => (
                  <div className="stats-item row" key={group.type}>
                    <div>
                      <p className="stats-label">{titleCase(group.type)}</p>
                      <p className="emphasis">{formatDecimal(group.score)}</p>
                    </div>
                    <button
                      disabled={group.stats === null}
                      onClick={() => setGroup(group.type)}
                    >
                      Details
                    </button>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "20px" }}>
                <div className="stats-item">
                  <p className="stats-label">Completed quizzes</p>
                  <p className="emphasis">{stats.completed_quizzes}</p>
                </div>
                <div className="stats-item">
                  <p className="stats-label">Answered questions</p>
                  <p className="emphasis">{stats.answered_questions}</p>
                </div>
                <div className="stats-item">
                  <p className="stats-label">Correct answers</p>
                  <p className="emphasis">{stats.correct_answers}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <NavBar />
    </>
  );
}
