import { ChangeEvent, useContext, useEffect, useState, JSX } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { logInUser } from "./services";
import { EMAIL_PATTERN, PASSWORD_PATTERN } from "./constants";
import { Loading } from "../shared/Loading";
import { Error } from "../shared/Error";
import { HttpStatus } from "../shared/constants";

export function LogIn(): JSX.Element {
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    http: "",
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [status, setStatus] = useState(HttpStatus.IDLE);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthenticatedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const missingFields = !email || !password;
  const activeErrors = Object.values(errors).includes(true);

  useEffect(() => {
    if (missingFields || activeErrors) {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [missingFields, activeErrors]);

  function updateEmail(event: ChangeEvent<HTMLInputElement>): void {
    if (!EMAIL_PATTERN.test(event.target.value)) {
      setErrors((errors) => ({ ...errors, email: true }));
    } else {
      setErrors((errors) => ({ ...errors, email: false }));
    }
    setEmail(event.target.value);
  }

  function updatePassword(event: ChangeEvent<HTMLInputElement>): void {
    if (!PASSWORD_PATTERN.test(event.target.value)) {
      setErrors((errors) => ({ ...errors, password: true }));
    } else {
      setErrors((errors) => ({ ...errors, password: false }));
    }
    setPassword(event.target.value);
  }

  async function handleLogIn(): Promise<void> {
    setStatus(HttpStatus.WORKING);
    try {
      await logInUser({
        email,
        password,
      });
      setAuthenticatedUser(true);
      setStatus(HttpStatus.SUCCESS);
      navigate("/vocabulary");
    } catch (error) {
      setErrors((errors) => ({ ...errors, http: (error as Error).message }));
      setStatus(HttpStatus.ERROR);
      console.error((error as Error).cause);
    }
  }

  function cancel(): void {
    navigate("/");
  }

  return (
    <>
      <h1 className="header">ログイン</h1>
      <div className="center form">
        <label>Email</label>
        <input onChange={updateEmail} required type="email" value={email} />
        <label>Password</label>
        <input
          onChange={updatePassword}
          required
          type={"password"}
          value={password}
        />
        {status === HttpStatus.ERROR && <Error message={errors.http} />}
        <div className="button-group">
          {status === HttpStatus.WORKING ? (
            <Loading />
          ) : (
            <>
              <button onClick={cancel}>Cancel</button>
              <button disabled={buttonDisabled} onClick={handleLogIn}>
                Log in
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
