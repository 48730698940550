import { useContext, JSX } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Welcome } from "./auth/Welcome";
import { CreateAccount } from "./auth/CreateAccount";
import { LogIn } from "./auth/LogIn";
import { Vocabulary } from "./vocabulary/Vocabulary";
import { Syllabary } from "./syllabary/Syllabary";
import { Quiz } from "./quiz/Quiz";
import { Stats } from "./quiz/Stats";
import { Account } from "./auth/Account";
import { AuthContext } from "./auth/AuthContext";
import "./index.css";

export function App(): JSX.Element {
  const { authenticatedUser } = useContext(AuthContext);

  return (
    <main>
      {authenticatedUser ? (
        <BrowserRouter>
          <Routes>
            <Route path="/vocabulary" element={<Vocabulary />} />
            <Route path="/syllabary" element={<Syllabary />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/account" element={<Account />} />
            <Route path="*" element={<Navigate replace to="/vocabulary" />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="*" element={<Navigate replace to="/welcome" />} />
          </Routes>
        </BrowserRouter>
      )}
    </main>
  );
}
