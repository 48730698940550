import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  JSX,
} from "react";

export const AuthContext = createContext<{
  authenticatedUser: boolean;
  setAuthenticatedUser: Dispatch<SetStateAction<boolean>>;
}>({ authenticatedUser: false, setAuthenticatedUser: () => true });

function isAuthenticated(): boolean {
  return document.cookie.split("; ").includes("a=1");
}

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [authenticatedUser, setAuthenticatedUser] = useState(isAuthenticated());

  return (
    <AuthContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>
      {children}
    </AuthContext.Provider>
  );
}
